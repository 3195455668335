import axios from 'axios'
import Widgets from '@/services/Widgets'
// import UserService from '@/services/UserService'

const BillingService = {
  async init () {
    return axios({
      url: axios.defaults.baseURL + 'billing/init',
      method: 'post'
    })
  },

  async save (data) {
    return axios.post(
      axios.defaults.baseURL + 'billing/edit/save',
      Widgets.JSON_to_URLEncoded(data),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    )
  },

  async alertdev (data) {
    return axios.post(
      axios.defaults.baseURL + 'billing/alertdev',
      Widgets.JSON_to_URLEncoded(data),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    )
  },

  async delete (id) {
    return axios.post(
      axios.defaults.baseURL + 'billing/delete',
      Widgets.JSON_to_URLEncoded({ id: id }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    )
  },

  async default (id) {
    return axios.post(
      axios.defaults.baseURL + 'billing/set-default',
      Widgets.JSON_to_URLEncoded({ id: id }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    )
  },

  async paymentInit () {
    return axios({
      url: axios.defaults.baseURL + 'account/payment/init',
      method: 'post'
    })
  },

  async payment (data) {
    return axios.post(
      axios.defaults.baseURL + 'account/payment/apply',
      Widgets.JSON_to_URLEncoded({ data: data }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    )
  }

}

export default BillingService
