<template>
  <b-container fluid v-if="address" class="mx-0 px-0">
    <b-row>
      <b-col sm="8">
        <b-form-group>
          <label class="control-label">Address *</label>
          <b-input-group>
            <b-form-input
              type="text"
              v-model="address.address1"
              :state="getFormState('address1')"
              @blur="$v.address.address1.$touch()"
              :disabled="disabled"
              placeholder="Address 1"
              autocomplete="address-line1"
            ></b-form-input>
            <b-form-invalid-feedback
              v-for="err in getErrors('address1', ['required'])"
              :key="err.id"
            >
              {{ err }}
            </b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group>
          <div class="spacer d-none d-sm-inline-block"></div>
          <b-input-group>
            <b-form-input
              type="text"
              v-model="address.address2"
              :disabled="disabled"
              placeholder="Address 2"
              autocomplete="address-line2"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="3">
        <b-form-group>
          <label class="control-label">Zip/Postal Code *</label>
          <b-input-group>
            <b-form-input
              type="text"
              v-model="address.zip"
              :state="getFormState('zip')"
              :disabled="disabled || searchZip"
              placeholder="Zip/Postal Code"
              autocomplete="postal-code"
              @input="handleZipInput"
              @blur="handleZipBlur"
              @keyup.enter="handleZipEnter"
            ></b-form-input>
            <b-form-invalid-feedback
              v-for="err in getErrors('zip', ['required', 'zipLength'])"
              :key="err.id"
            >
              {{ err }}
            </b-form-invalid-feedback>
            <b-input-group-append v-show="searchZip">
              <b-input-group-text>
                <b-icon icon="arrow-repeat" animation="spin"></b-icon>
              </b-input-group-text>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col sm="3">
        <b-form-group>
          <label class="control-label">City *</label>
          <b-input-group>
            <b-form-input
              type="text"
              v-model="address.city"
              :state="getFormState('city')"
              @blur="$v.address.city.$touch()"
              :disabled="disabled || searchZip"
              placeholder="City"
              autocomplete="address-level2"
            ></b-form-input>
            <b-form-invalid-feedback
              v-for="err in getErrors('city', ['required'])"
              :key="err.id"
            >
              {{ err }}
            </b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col sm="3">
        <b-form-group>
          <label class="control-label">State/Province *</label>
          <b-form-select
            v-model="address.state_id"
            v-if="statesByCountry.length > 0"
            :options="statesByCountry"
            :disabled="disabled || searchZip"
            value-field="id"
            text-field="name"
            :state="getFormState('state')"
            @input="stateSelect()"
            @blur.native="$v.address.state.$touch()"
            autocomplete="address-level1"
          >
            <template #first>
              <b-form-select-option :value="null" disabled
                >Select</b-form-select-option
              >
            </template>
          </b-form-select>
          <b-form-input v-else
            v-model="address.state"
            :disabled="disabled || searchZip"
            :state="getFormState('state')"
            @blur.native="$v.address.state.$touch()"
            autocomplete="address-level1"
          ></b-form-input>
          <b-form-invalid-feedback
            v-for="err in getErrors('state', ['required'])"
            :key="err.id"
          >
            {{ err }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col sm="3">
        <b-form-group>
          <label class="control-label">Country *</label>
          <b-form-select
            v-model="address.country_id"
            :options="countries"
            :disabled="disabled || searchZip"
            value-field="id"
            text-field="name"
            :state="getFormState('country_id')"
            @input="$v.address.country_id.$touch()"
            @blur.native="$v.address.country_id.$touch()"
            autocomplete="country"
          >
            <template #first>
              <b-form-select-option :value="null" disabled
                >Select Country</b-form-select-option
              >
            </template>
          </b-form-select>
          <b-form-invalid-feedback
            v-for="err in getErrors('country_id', ['required'])"
            :key="err.id"
          >
            {{ err }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import AccountService from '@/services/AccountService'
const zipLength = function (value) {
  if (!value) return true
  return value.length >= 4
}
export default {
  props: {
    mainaddress: {
      type: Object,
      default () { return null }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    states: {
      type: Array,
      default: () => { return [] }
    },
    countries: {
      type: Array,
      default: () => { return [] }
    },
    isRegistration: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      searchZip: false,
      initialSetup: true,
      address: {
        address1: null,
        address2: null,
        zip: null,
        city: null,
        state: null,
        state_id: null,
        country_id: null
      }
    }
  },

  mounted () {
    this.setAddress(this.mainaddress)
    if (this.isRegistration) {
      this.$v.address.$touch()
    }
  },

  methods: {
    getErrors: function (field, validators) {
      const err = []
      if (!this.$v.address[field].$dirty) return err

      validators.forEach(e => {
        if (!this.$v.address[field][e]) {
          if (e === 'required' && !this.isRegistration) {
            err.push('This field is required')
          }
          if (e === 'minLength') {
            err.push(field.capitalize() + ' must be at least ' + this.$v.address[field].$params.minLength.min + ' characters')
          }
          if (
            e === 'zipLength' &&
            this.address.zip != null &&
            this.address.zip.length < 5 &&
            (this.address.country_id == null || +this.address.country_id === 184)
          ) {
            err.push(`Given ${field.capitalize()} is not a valid United States Zipcode.`)
          }
        }
      })

      return err
    },
    handleZipInput () {
      const zip = this.address.zip || ''
      if (zip.length > 5) {
        this.checkZip()
      }
    },
    handleZipBlur () {
      this.$v.address.zip.$touch()
      const zip = this.address.zip || ''
      if (zip.length === 4 || zip.length === 5) {
        this.checkZip()
      }
    },
    handleZipEnter () {
      const zip = this.address.zip || ''
      if (zip.length === 4 || zip.length === 5) {
        this.checkZip()
      }
    },
    checkZip: function () {
      if (this.$v.address.zip.$invalid) {
        return
      }

      this.searchZip = true
      AccountService.checkZip(this.address.zip).then(
        (response) => {
          if (response && response.data && response.data.locale && response.data.locale.state_id) {
            this.address.country_id = response.data.locale.country_id
            const state = this.states.filter(i => +i.id === +response.data.locale.state_id)
            if (state.length === 1) {
              this.address.state = state[0].abbreviation
              this.address.state_id = state[0].id
            }
            this.address.city = response.data.locale.city
          }
        }
      ).catch((error) => {
        this.$aim.error(error.response)
      }).finally(() => { this.searchZip = false })
    },
    stateSelect: function () {
      this.$v.address.state.$touch()

      const state = this.states.filter(i => i.id === this.address.state_id)
      if (state.length === 1) {
        this.address.state = state[0].abbreviation
      }
    },
    setAddress: function (address) {
      if (address) {
        // fix zip code padding if needed.
        address.zip = ('' + address.zip + '').padStart(5, '0')
        this.address = Object.assign({}, address)
      }
    },

    getFormState (field) {
      if (this.isRegistration) {
        return !this.$v.address[field].$error
      } else {
        return !this.$v.address[field].$error ? null : false
      }
    }
  },
  computed: {
    statesByCountry () {
      if (this.address.country_id) {
        return this.states.filter(e => +e.country_id === +this.address.country_id)
      } else {
        return this.states
      }
    }
  },
  watch: {
    '$v.$invalid': function () {
      this.$emit('isValid', !this.$v.$invalid)
    },
    address: {
      deep: true,
      handler () {
        if (this.initialSetup) {
          if (this.address.state && !this.address.state_id) {
            const state = this.states.filter(i => i.abbreviation === this.address.state)
            if (state.length === 1) {
              this.address.state_id = state[0].id
            }
          }
          if (this.address.state_id && !this.address.state) {
            const state2 = this.states.filter(i => i.id === this.address.state_id)
            if (state2.length === 1) {
              this.address.state = state2[0].abbreviation
            }
          }
          this.initialSetup = false
        }
        this.address.valid = !this.$v.$invalid
        this.$emit('dataChange', this.address)
      }
    },
    /**
     * When you pass in props and assign on mounted, you have to watch
     * for changes, particularly if the data is pulled via ajax,
     * since it won't be anything on the mount
     */
    mainaddress: function (n) {
      this.setAddress(n)
    }
  },
  mixins: [validationMixin],
  validations: {
    address: {
      address1: {
        required
      },
      zip: {
        required,
        zipLength
      },
      city: {
        required
      },
      state: {
        required
      },
      country_id: {
        required
      }
    }
  }
}
</script>
<style lang="css">
.spacer {
  line-height: 1.5;
  height: 1rem;
  margin-bottom: 0.65rem;
  display: inline-block;
}
</style>
